var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "pb-1"
        }, [_vm._v("mdi-list-box-outline")]), _vm._v(" NUS-koderegister ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mr-5",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "single-line": "",
            "clearable": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "openCreateNusCode"
          },
          on: {
            "click": _vm.openCreateNusCode
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Lag ny NUS-kode ")], 1)];
      },
      proxy: true
    }])
  }, [_vm.modalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 600
    },
    on: {
      "close": function close($event) {
        return _vm.closeModal(_vm.modalData);
      }
    }
  }, [_c('NusCodeModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "existingItemModal": _vm.modalData.existingItem
    },
    on: {
      "close": function close($event) {
        return _vm.closeModal(_vm.modalData);
      },
      "refreshNusCodes": function refreshNusCodes($event) {
        return _vm.refreshNusCodes();
      }
    }
  })], 1) : _vm._e(), [_c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.nusCodes,
      "search": _vm.search,
      "sort-by": "code",
      "titleClass": "py-0"
    },
    scopedSlots: _vm._u([{
      key: "item.isActive",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value ? "Aktiv" : "Inaktiv") + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "data-cy": "openEditNusCode"
                },
                on: {
                  "click": function click($event) {
                    return _vm.openEditNusCode(item, item.name);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger " + _vm._s(item.name))])])];
      }
    }], null, true)
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }