
import { api } from "@/api/api";
import { ApiGetNusCodeDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import NusCodeModal from "@/components/administration/nus-code/NusCodeModal.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "NusCodesPage",
  components: {
    BaseTableFiltered,
    BaseLayout,
    BaseModal,
    NusCodeModal,
  },
  setup() {
    const search = ref<string>("");
    const modalData = ref(getInitialModalData());
    const nusCodes = ref<ApiGetNusCodeDto[]>();

    const loadNusCodes = async () => {
      nusCodes.value = (await api.curriculum.getNusCodes()).data;
    };

    const openCreateNusCode = useOpenModal(ModalType.Add, "NUS-kode", modalData);
    const openEditNusCode = useOpenModal(ModalType.Edit, "NUS-kode", modalData);

    const closeModal = async (modalData: ModalBaseData) => {
      modalData.showModal = false;
      resetModalData();
    };

    const refreshNusCodes = async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        await loadNusCodes();
      });
    };

    const resetModalData = () => {
      modalData.value = getInitialModalData();
    };

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        await loadNusCodes();
      });
    });

    return {
      headers,
      search,
      modalData,
      closeModal,
      openCreateNusCode,
      openEditNusCode,
      nusCodes,
      refreshNusCodes,
    };
  },
});

const headers = [
  { text: "NUS-kode", value: "code" },
  { text: "Navn", value: "name" },
  { text: "Kortnavn", value: "shortName" },
  { text: "Status", value: "isActive" },
  { text: "", value: "actions", sortable: false },
];
