var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "NUS-kode",
      "rules": [].concat(_vm.validateNotEmpty, [_vm.validateNusCode]),
      "counter": 6,
      "readonly": _vm.isEditModal,
      "required": "",
      "data-cy": "nusCode"
    },
    model: {
      value: _vm.nusCode.code,
      callback: function callback($$v) {
        _vm.$set(_vm.nusCode, "code", $$v);
      },
      expression: "nusCode.code"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Navn",
      "rules": [].concat(_vm.validateNotEmpty),
      "required": "",
      "data-cy": "nusName"
    },
    model: {
      value: _vm.nusCode.name,
      callback: function callback($$v) {
        _vm.$set(_vm.nusCode, "name", $$v);
      },
      expression: "nusCode.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Kortnavn",
      "rules": [].concat(_vm.validateNotEmpty),
      "required": "",
      "data-cy": "nusShortName"
    },
    model: {
      value: _vm.nusCode.shortName,
      callback: function callback($$v) {
        _vm.$set(_vm.nusCode, "shortName", $$v);
      },
      expression: "nusCode.shortName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": _vm.nusCode.isActive ? 'Aktiv' : 'Inaktiv',
      "color": "primary",
      "inset": "",
      "hide-details": "",
      "data-cy": "nusIsActive"
    },
    model: {
      value: _vm.nusCode.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.nusCode, "isActive", $$v);
      },
      expression: "nusCode.isActive"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }